import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Call from '../../components/Call';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Track" />
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Track a Colony</h1>
            <hr/>
            <p>To track a colony send us an email or give us a call...</p>
            <div className="container">
              <div className="row">
                <div className="col-12 mb-2">
                  <Call button={false} />
                </div>
              </div>
            </div>
            <p> Help us track it and learn more about wild bees in Colorado.</p>
            <p><h3>What do I need to do?</h3> Just tell us about the colony, where it is, what they are living in, and if you can send a picture.
               Then, if you can, give us an update a few times a year to let us know they are surviving and how active they are.
            </p>
            <p><h3>What happens to the bees?</h3> The bees will remain <i>UNHARMED</i> and <i>IN PLACE</i>. If possible we will visit the colony to document it further. 
            We may take a small sample to test for disease or do genetic testing, but will not harm or damage the hive to do so. If you want the colony removed please see our <Link to="/swarms">swarms page</Link>.
            </p>
            <h3>Find a colony</h3>
            <p>Don't know where a colony is, but want to? We'll help you learn step by step how to find bee hives. <br/>
              <Link className="button mt-2" to="/bees/find-colonies">
                Find a Colony
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
